import axios from "axios";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";

import { DIProps } from "@di";

import { IncidentSummary } from "@models/apronAlert";
import { FutureFlight } from "@models/flightInfo";
import {
  IncidentSeverity,
  SAFETY_TYPES,
  SafetyEventIncidentConfig,
  SafetyType,
} from "@models/incidentConfig";
import { TOperationWidget } from "@models/operationWidget";
import { GlobalStand } from "@models/stand";
import { IWatchlist } from "@models/watchlist";
import { camelCaseKeys, filterItemsByStandPatterns } from "@services/data";

import { generateLabelById } from "@services/standLabelUtils";
import { isFiniteNumber } from "@services/utils";

import {
  getActiveTurnaroundsApiConfig,
  getAircraftsApiConfig,
  getExportApiConfig,
  getFetchDetectionApiConfig,
  getFlightsApiConfig,
  getFullTurnaroundApiConfig,
  getIncidentsApiConfig,
  getIncidentsSummaryApiConfig,
  getOperationWidgetsApiConfig,
  getSafetyConfigsApiConfig,
  getStandsApiConfig,
  getStandTurnaroundsApiConfig,
  getTimelineApiConfig,
  getTurnaroundsApiConfig,
  getTurnaroundTimelineApiConfig,
  getUserWatchlistApiConfig,
  getUserWatchlistPostApiConfig,
  IncidentsParams,
  IncidentsResponse,
  searchTurnaroundsApiConfig,
  TActiveTurnaroundsResponse,
  TAircraftsResponse,
  TDetectionResponse,
  TExportResponse,
  TFlightsResponse,
  TGetStandsResponse,
  TIncidentsSummaryResponse,
  TOperationWidgetsResponse,
  TSafetyConfigsResponse,
  TSearchTurnaroundsResponse,
  TStandTimelineResponse,
  TStandTurnaroundsParams,
  TStandTurnaroundsResponse,
  TTurnaroundResponse,
  TTurnaroundsParams,
  TTurnaroundsResponse,
  TTurnaroundsSummaryParams,
  TTurnaroundsSummaryResponse,
  TTurnaroundTimelineResponse,
  TUserWatchlistGetResponse,
  TUserWatchlistPostParams,
  TUserWatchlistPostResponse,
} from "@api/configs";
import { prepareTurnaroundParams } from "@api/parsers/prepareTunraroundParams";
import { TurnaroundParams } from "@models/TurnaroundParams";
import { Detection } from "@models/detection";

import {
  parseAlert,
  parseCameraOutage,
  parseDetection,
  parseDetections,
  parseIncidentConfig,
  parsePartialDetection,
  parsePts,
  parseTimestamps,
  parseTimestampsDict,
  parseTurnaround,
  parseTurnarounds,
  parseTurnParams,
} from "../parsers";
import {
  TAddAlertsResult,
  TAircraftsResult,
  TimelineResult,
  TLoadedIncidentsResult,
  TLoadedSafetyEventsResult,
  TurnaroundResult,
  TurnaroundsResult,
  TurnaroundTimelineResult,
  WatchlistTurnaroundState,
} from "../types";

export class Api {
  constructor(private _di: DIProps) {
    console.log("init api");
  }

  get standPatterns() {
    return this._di.config.standPatterns;
  }

  private get _request() {
    return this._di.apiClient.performRequest;
  }

  getStands() {
    const { config } = getStandsApiConfig();

    return this._request<GlobalStand[], TGetStandsResponse>(
      config,
      (rawStands = []) => {
        return rawStands.map((rawStand) => {
          const generatedStandLabel = generateLabelById(rawStand.id);
          const label = generatedStandLabel;

          const cameras = orderBy(
            rawStand.camera_ids,
            [(id) => id.indexOf("-s"), (id) => id],
            ["desc", "asc"]
          ).map((c: string) => {
            let generatedCameraLabel = generateLabelById(c);

            // Replace stand label part in camera with the actual stand label
            generatedCameraLabel = generatedCameraLabel.replace(
              generatedStandLabel,
              label
            );

            return {
              id: c,
              label: generatedCameraLabel,
            };
          });

          return {
            id: rawStand.id,
            label,
            cameras,
          };
        });
      }
    );
  }

  getStandTurnarounds(
    standId: string,
    params: TStandTurnaroundsParams,
    signal?: AbortSignal
  ) {
    const { config } = getStandTurnaroundsApiConfig(standId, params, signal);
    return this._request<TurnaroundsResult, TStandTurnaroundsResponse>(
      config,
      this._parseTurnarounds
    );
  }

  async searchTurnarounds(
    stand: string | null | undefined,
    query: string,
    before?: number,
    limit = this._di.config.turnaroundsRequestLimit,
    signal?: AbortSignal
  ) {
    if (!query) {
      return { turnarounds: [], fetchedCount: 0 };
    }

    const { config } = searchTurnaroundsApiConfig(
      {
        stand_id: stand || undefined,
        q: query,
        before,
        limit,
      },
      signal
    );

    try {
      return await this._request<TurnaroundsResult, TSearchTurnaroundsResponse>(
        config,
        this._parseTurnarounds
      );
    } catch (error) {
      // handle API 400 response bug, when the query doesn't contain at lease one of a-Z or 0-9 characters
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        return { turnarounds: [], fetchedCount: 0 };
      }

      throw error;
    }
  }

  getTurnaround(standId: string, turnId: string) {
    const { config } = getFullTurnaroundApiConfig(standId, turnId);

    return this._request<TurnaroundResult | null, TTurnaroundResponse>(
      config,
      (data) => {
        if (!data) {
          return null;
        }

        const { detections, pts, ...rest } = data.turnaround;

        const turnaround = this._parseTurnaround(rest);

        if (!turnaround) {
          return null;
        }

        return {
          ...parseTimestamps(data),
          online: data.online,
          turnaround,
          pts: parsePts(pts),
          detections: detections?.map(parsePartialDetection) || [],
        };
      }
    );
  }

  timeline(standId: string, startTs: number, endTs?: number | null) {
    const { config } = getTimelineApiConfig(standId, startTs, endTs);

    return this._request<TimelineResult, TStandTimelineResponse>(
      config,
      (data) => ({
        ...parseTimestamps(data),
        turnarounds: this._parseTurnarounds(data.turnarounds).turnarounds,
        detections: parseDetections(
          data.detections,
          standId,
          parsePartialDetection
        ),
        outages: data.camera_outages.map(parseCameraOutage),
      })
    );
  }

  // TODO spec is missing (use turn endpoint)
  turnaroundTimeline(standId: string, turnId: string) {
    const { config } = getTurnaroundTimelineApiConfig(standId, turnId);

    return this._request<
      TurnaroundTimelineResult | null,
      TTurnaroundTimelineResponse
    >(config, (data) => {
      const parsedTurnaround = this._parseTurnaround(data.turnaround);

      if (!parsedTurnaround) {
        return null;
      }

      return {
        ...parseTimestamps(data),
        turnaround: parsedTurnaround,
        detections: parseDetections(
          data.detections || [],
          standId,
          parsePartialDetection
        ),
        outages: data.camera_outages?.map(parseCameraOutage) || [],
      };
    });
  }

  fetchDetection(id: string) {
    const { config } = getFetchDetectionApiConfig(id);

    return this._request<Detection, TDetectionResponse>(config, parseDetection);
  }

  getActiveTurnarounds(lastRequestTs?: number) {
    const { config } = getActiveTurnaroundsApiConfig(lastRequestTs);
    return this._request<TurnaroundsResult, TActiveTurnaroundsResponse>(
      config,
      this._parseTurnarounds
    );
  }

  async getAlertsForSidebar(
    params: IncidentsParams = {},
    options: {
      filterAlertsFromOtherAirports: boolean;
    }
  ): Promise<TLoadedIncidentsResult> {
    params = {
      ...params,
      incident_type: ["unified", "safety-alert"],
    };

    const result = await this._getIncidents(
      params,
      options.filterAlertsFromOtherAirports
    );

    return {
      alerts: [result.unifiedIncidents, result.safetyAlertIncidents].flat(),
      actualAlertCount: result.actualAlertCount,
    };
  }

  async getSafetyEvents(
    params: IncidentsParams = {},
    filterAlertsFromOtherAirports = true
  ): Promise<TLoadedSafetyEventsResult> {
    params = {
      ...params,
      incident_type: ["safety-event", "weighted-safety-event"],
    };

    const result = await this._getIncidents(
      params,
      filterAlertsFromOtherAirports
    );

    return {
      safetyEvents: result.safetyEventIncidents,
      actualAlertCount: result.actualAlertCount,
    };
  }

  getAlertsConfigList() {
    const { config } = getSafetyConfigsApiConfig();

    return this._request<SafetyEventIncidentConfig[], TSafetyConfigsResponse>(
      config,
      (rawConfigs = []) => {
        const result: SafetyEventIncidentConfig[] = [];

        for (const raw of rawConfigs) {
          const parsedData = parseIncidentConfig(raw);
          const data = parsedData.data;

          switch (data.incidentType) {
            case "safety-event": {
              // TODO / FIXME: It is unreliable to use customText to determine chocks, speed and etc.

              let safetyType: SafetyType = SAFETY_TYPES.DEFAULT;
              if (parsedData.customText?.includes(":")) {
                const [
                  customSafetyType = SAFETY_TYPES.DEFAULT,
                  customText = "",
                ] = parsedData.customText.split(":") as [SafetyType, string];

                safetyType = customSafetyType;
                parsedData.customText = customText;
              }

              if (data.type === "weighted-safety-event") {
                safetyType = SAFETY_TYPES.WEIGHTED;
              }

              if (data.type === "pushback-angle-safety-event") {
                safetyType = SAFETY_TYPES.TOWBAR_ANGLE;
              }

              result.push({
                ...parsedData,
                safetyType,
                data,
              });
              break;
            }
          }
        }

        return result;
      }
    );
  }

  makeExport(standId: string, startTs: number, endTs: number) {
    const { config } = getExportApiConfig(standId, {
      start_ts: startTs,
      end_ts: endTs,
    });

    config.timeout = 60 * 5 * 1000;

    type Result = Pick<TimelineResult, "detections" | "turnarounds">;

    return this._request<Result, TExportResponse>(config, (data) => ({
      detections: parseDetections(data.detections, standId, parseDetection),
      // TODO as any
      turnarounds: this._parseTurnarounds(data.turnarounds as any).turnarounds,
    }));
  }

  getIncidentsSummary = () => {
    const { config } = getIncidentsSummaryApiConfig();

    return this._request<
      Record<string, IncidentSummary>,
      TIncidentsSummaryResponse
    >(config, (data) => {
      const result: IncidentSummary[] = [];

      for (const { stand_id, incidents } of data) {
        if (!stand_id || !incidents) {
          continue;
        }

        const keyedSummary: Partial<IncidentSummary["keyedSummary"]> = {};

        const parsedIncidents: IncidentSummary["incidents"] = [];

        for (const { severity, count } of incidents) {
          if (!severity || !count) {
            continue;
          }

          keyedSummary[severity as unknown as IncidentSeverity] = count;

          parsedIncidents.push({
            severity: severity as unknown as IncidentSeverity,
            count,
          });
        }

        result.push({
          standId: stand_id,
          incidents: parsedIncidents,
          keyedSummary,
        });
      }

      const filteredResult = filterItemsByStandPatterns(
        result,
        (v) => v.standId,
        this.standPatterns
      );

      return keyBy(filteredResult, (v) => v.standId);
    });
  };

  loadImageAjax = async (url: string, signal?: AbortSignal) => {
    const resp = await fetch(url, { signal });

    if (!resp.ok) {
      throw resp;
    }

    const blob = await resp.blob();

    return URL.createObjectURL(blob);
  };

  getWatchlist() {
    const { config } = getUserWatchlistApiConfig();
    return this._request<IWatchlist | undefined, TUserWatchlistGetResponse>(
      config
    );
  }

  createWatchlist(data: TUserWatchlistPostParams) {
    const { config } = getUserWatchlistPostApiConfig(data);
    return this._request<IWatchlist, TUserWatchlistPostResponse>(config);
  }

  removeWatchlist() {
    return this._request<void>({
      method: "DELETE",
      url: "/api/v2/user-watchlist/current",
    });
  }

  getWatchlistActiveTurnarounds() {
    return this.getTurnarounds({
      watchlist: true,
      authorized: true,
      active: true,
    });
  }

  async getTurnaroundWidgets(id: string) {
    const { config } = getOperationWidgetsApiConfig(id);

    return await this._request<TOperationWidget[], TOperationWidgetsResponse>(
      config,
      camelCaseKeys
    );
  }

  getFlights(
    type: "arrivals" | "departures",
    params: {
      from?: number;
      to?: number;
      flightNumbers?: string[];
      watchlist?: boolean;
    },
    signal?: AbortSignal
  ): Promise<FutureFlight[]> {
    const { config } = getFlightsApiConfig(type, params, signal);

    return this._request<FutureFlight[], TFlightsResponse>(config, (data) => {
      return data.map((flight) => {
        let parsedParams: TurnaroundParams = parseTurnParams(flight);

        // Overwrite flight's params with turn's params if they are present
        if (flight.turnaround_params) {
          parsedParams = {
            ...parsedParams,
            ...parseTurnParams(flight.turnaround_params),
          };
        }

        const preparedParams = prepareTurnaroundParams(
          parsedParams,
          this._di.config.turnParamsSource
        );

        const result: FutureFlight = {
          id: flight.data_hash,
          standId: flight.stand_id || "",
          flightNumber: flight.full_flight_number || "",
          companyIata: flight.company_iata,
          departureAirport: flight.departure_airport || null,
          arrivalAirport: flight.arrival_airport || null,
          aircraftType: flight.aircraft_type || null,
          aircraftRegNumber: flight.aircraft_reg_number || null,
          rawFlightStatus: flight.raw_flight_status || null,

          params: preparedParams,
          hasMatchedTurnaround: Boolean(flight.turnaround_params),
        };

        return result;
      });
    });
  }

  getTurnarounds(
    params: TTurnaroundsParams,
    withSummary?: false,
    signal?: AbortSignal
  ): Promise<WatchlistTurnaroundState[]>;
  getTurnarounds(
    params: TTurnaroundsSummaryParams,
    withSummary?: true,
    signal?: AbortSignal
  ): Promise<WatchlistTurnaroundState[]>;
  async getTurnarounds(
    params: TTurnaroundsParams | TTurnaroundsSummaryParams,
    withSummary: boolean = false,
    signal?: AbortSignal
  ): Promise<WatchlistTurnaroundState[]> {
    const configs: ReturnType<typeof getTurnaroundsApiConfig>["config"][] = [];

    // Workaround for fetching both active and inactive turnarounds, if `active` is not specified
    // TODO: fix on the backend side
    //  (omitting the `active` flag should return both active and inactive turnarounds)
    if (typeof params.active === "undefined") {
      configs.push(
        getTurnaroundsApiConfig(
          { ...params, active: true },
          withSummary,
          signal
        ).config,
        getTurnaroundsApiConfig(
          { ...params, active: false },
          withSummary,
          signal
        ).config
      );
    } else {
      // Regular case - fetch turnarounds with the specified `active` flag
      configs.push(getTurnaroundsApiConfig(params, withSummary, signal).config);
    }

    const parseResponse = (
      data: TTurnaroundsResponse | TTurnaroundsSummaryResponse
    ) => {
      const result: WatchlistTurnaroundState[] = compact(
        data.map((item) => {
          const turnaround = this._parseTurnaround(item.turnaround);
          if (!turnaround) {
            return null;
          }

          return {
            turnaround,
            lastImageTimestamp: parseTimestampsDict(item.last_image_ts),
            operationWidgets: camelCaseKeys(item.operation_widgets),
            alertsCount:
              "alerts_count" in item ? item.alerts_count ?? null : null,
            safetyTriggered:
              "safety_flag" in item ? item.safety_flag ?? null : null,
            alertsSummary:
              "alerts_summary" in item
                ? item.alerts_summary?.map(({ count, severity }) => ({
                    severity: severity as IncidentSeverity,
                    count: count,
                  })) ?? null
                : null,
          } as WatchlistTurnaroundState;
        })
      );

      return result;
    };

    const requests = configs.map((config) =>
      this._request<
        WatchlistTurnaroundState[],
        TTurnaroundsResponse | TTurnaroundsSummaryResponse
      >(config, parseResponse)
    );
    const responses = await Promise.all(requests);

    // Return all the results, asserting there are no same turnarounds in both responses,
    // since they are fetched with a different `active` flag
    return responses.flat();
  }

  getAircrafts() {
    const { config } = getAircraftsApiConfig();
    return this._request<TAircraftsResult, TAircraftsResponse>(config, (data) =>
      data.map((v) => ({
        groupName: v.group_name,
        aircrafts: v.group_types,
      }))
    );
  }

  private _parseTurnaround = (data: unknown) => {
    const { fixPOBTOutOfBounds, turnParamsSource } = this._di.config;
    return parseTurnaround(data, fixPOBTOutOfBounds, turnParamsSource);
  };

  private _parseTurnarounds = (
    data: TStandTurnaroundsResponse | TSearchTurnaroundsResponse
  ) => {
    const {
      fixPOBTOutOfBounds,
      turnaroundsStartTimestamp,
      turnaroundsVisibleRangeSizeByRole,
      turnParamsSource,
    } = this._di.config;
    const user = this._di.user;

    return parseTurnarounds(data, user, {
      fixPOBTOutOfBounds,
      turnaroundsStartTimestamp,
      turnaroundsVisibleRangeSizeByRole,
      turnParamsSource,
    });
  };

  private _getIncidents(
    params: IncidentsParams,
    filterAlertsFromOtherAirports: boolean
  ) {
    const { turnaroundsStartTimestamp } = this._di.config;

    let startTs = 0;
    const { stand_id: standId } = params;

    if (isFiniteNumber(turnaroundsStartTimestamp)) {
      startTs = turnaroundsStartTimestamp;
    } else if (turnaroundsStartTimestamp && standId) {
      startTs = turnaroundsStartTimestamp[standId] || 0;
    }

    const config = getIncidentsApiConfig(params);

    return this._request<TAddAlertsResult, IncidentsResponse>(
      config.config,
      ({ incidents: rawConfigs = [], total_count }: any) => {
        if (startTs) {
          rawConfigs = rawConfigs.filter(({ ts }: any) => ts >= startTs);
        }

        const result: TAddAlertsResult = {
          unifiedIncidents: [],
          safetyAlertIncidents: [],
          safetyEventIncidents: [],
          actualAlertCount: total_count,
        };

        for (const raw of rawConfigs) {
          const parsedData = parseAlert(raw);
          const data = parsedData.data;

          switch (data.incidentType) {
            case "unified": {
              result.unifiedIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }

            case "safety-alert": {
              result.safetyAlertIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }

            case "safety-event": {
              result.safetyEventIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }
          }
        }

        if (filterAlertsFromOtherAirports) {
          const { standPatterns } = this;
          result.unifiedIncidents = filterItemsByStandPatterns(
            result.unifiedIncidents,
            (v) => v.standId,
            standPatterns
          );
          result.safetyAlertIncidents = filterItemsByStandPatterns(
            result.safetyAlertIncidents,
            (v) => v.standId,
            standPatterns
          );
          result.safetyEventIncidents = filterItemsByStandPatterns(
            result.safetyEventIncidents,
            (v) => v.standId,
            standPatterns
          );
        }

        return result;
      }
    );
  }
}
